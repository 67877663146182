import '../vendors/fontawesome-free-5.1.0/scss/fa-regular.scss'
import '../vendors/fontawesome-free-5.1.0/scss/fa-solid.scss'
import '../vendors/fontawesome-free-5.1.0/scss/fa-brands.scss'
import '../scss/main.scss'
import $ from 'jquery'
import '../vendor/bootstrap-3.3.0/dist/js/bootstrap'
import './advancedSearch'

$(document).ready(function (w) {

    const hardfacts = w('.hardfacts');

    function setIcons(DomElement) {
        var icons = {
            'Kaufpreis': '<i class="flaticon-148-money-icons"></i>',
            'Kaltmiete': '<i class="flaticon-052-savings"></i>',
            'Warmmiete': '<i class="flaticon-052-savings"></i>',
            'Pacht': '<i class="flaticon-148-money-icons"></i>',
            'Nebenkosten': '<i class="flaticon-134-plumbering-1"></i>',
            'Wohnfläche': '<i class="flaticon-053-interior-design"></i>',
            'Nutzfläche': '<i class="flaticon-088-cleaned"></i>',
            'Grundstücksfläche': '<i class="flaticon-095-measuring"></i>',
            'Grundstückfläche': '<i class="flaticon-095-measuring"></i>',
            'Gewerbefläche': '<i class="flaticon-044-industrial-park"></i>',
            'Lagerfläche': '<i class="flaticon-017-plans"></i>',
            'Bürofläche': '<i class="flaticon-007-property"></i>',
            'Kellerfläche': '<i class="flaticon-093-packaging"></i>',
            'Gastraumfläche': '<i class="flaticon-017-plans"></i>',
            'Gesamtfläche': '<i class="flaticon-016-houses"></i>',
            'Anzahl Zimmer': '<i class="flaticon-117-beds"></i>',
            'Anzahl Gewerbeeinheiten': '<i class="flaticon-044-industrial-park"></i>',
            'Anzahl Wohneinheiten': '<i class="flaticon-050-fireplace-1"></i>',
        };

        w.each(DomElement, function (i, v) {
            const label = w(v.children[1]).html().trim();
            const elem = w(this);

            if (icons.hasOwnProperty(label)) {
                elem.prepend(icons[label]);
            }

        });

    };

    if (w('#image-carousel').length) {
        const slider = w('#image-carousel').carousel({interval: 5000})
    }

    /**
     * @description function to expand the Content Area in OnePage Template to full height
     */
    if (w('#single-more-button').length) {

        (function () {
            var elementHeight = w('#beschreibung').css('height');

            w('#single-more-button').click(function () {
                return (this.tog = !this.tog) ?
                    function () {
                        w('#beschreibung').css({'height': 'auto', 'transition': 'height 2s'});
                    }() :
                    function () {
                        w('#beschreibung').css('height', elementHeight);
                    }()
            });
        })();
    }

    /**
     * @description add pagination class to .page-numbers
     */
    if (w('.page-numbers').length) {
        w('.page-numbers').addClass('pagination');
    }

    /*function Sorter(listSelector,itemSelector,orderSelector,order)
    {
        var items=$(listSelector).clone(true).find(itemSelector);

        items.sort(($.grep(items,function(e){return isNaN(parseFloat($(e).find(orderSelector).eq(0).text()));}).length)
            ?(function(a,b)
            {
                var c=(order=='desc')?[b,a]:[a,b]; console.log(c);
                return ($(c[0]).find(orderSelector).eq(0).text()>$(c[1]).find(orderSelector).eq(0).text())?1:-1;
            })
            :(function(a,b)
            {
                var c=(order=='desc')?[b,a]:[a,b]; console.log(c);
                return (parseFloat($(c[0]).find(orderSelector).eq(0).text())-parseFloat($(c[1]).find(orderSelector).eq(0).text()));
            })
        );

        for(var i=0;i<items.length;++i)
        {
            $(listSelector+' '+itemSelector).eq(i).replaceWith(items[i]);
        }
    }*/

    if (w('.single-wpi_immobilie') && w(hardfacts).length) {
        setIcons(hardfacts);
    }
    // Filter the h3 text on Content
    if (w('#dreizeiler h3').length) {
        w('#dreizeiler h3').html('Kurzbeschreibung');
    }
    if (w('#ausstatt_beschr h3').length) {
        w('#ausstatt_beschr h3').html('Ausstattung');
    }
    if (w('#sonstige_angaben h3').length) {
        w('#sonstige_angaben h3').html('Sonstiges');
    }
    if (w('#user_defined_simplefield h3').length) {
        w('#user_defined_simplefield h3').html('Weitere Angaben');
    }

});
