<template>
  <div class="row relative">
    <form @submit.prevent="onSubmit">
      <spinner v-if="loading"></spinner>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="land">Land</label>
        <select id="land" class="form-control" v-model="formData.land">
          <option v-for="land in selectOptions.laender">{{ land }}</option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="ort">Ort</label>
        <select id="ort" class="form-control" v-model="formData.ort">
          <option v-for="(ort, key) in selectOptions.orte">{{ selectOptions.orte[key] }}</option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="objekttyp">Objekttyp</label>
        <select id="objekttyp" class="form-control" v-model="formData.objekttyp">
          <option v-for="(objekt, key) in selectOptions.objekttyp">{{ selectOptions.objekttyp[key].name }}</option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="vermarktung">Vermarktung</label>
        <select id="vermarktung" class="form-control" v-model="formData.vermarktung">
          <option v-for="(vermarktung, key) in selectOptions.vermarktung">
            {{ selectOptions.vermarktung[key].name }}
          </option>
        </select>
      </div>
      <div class="form-group col-xs-4">
        <label for="flaeche">Fläche {{ formData.flaeche ? `(ab ${formData.flaeche} m²)` : '' }}</label>
        <input type="range" id="flaeche" class="form-control" v-model="formData.flaeche" min="50" max="500" step="10">
      </div>
      <div class="form-group col-xs-4">
        <label for="zimmer">Zimmer {{ formData.zimmer }}</label>
        <input type="range" id="zimmer" class="form-control" v-model="formData.zimmer" min="0" max="10">
      </div>
      <div class="form-group col-xs-4">
        <label for="preis">Preis {{ formData.preis ? `(bis ${formData.preis} €)` : '' }}</label>
        <input type="range" id="preis" class="form-control" v-model="formData.preis" min="1000" max="1000000"
               step="100">
      </div>
      <div id="message-area" class="form-group col-xs-12">
        <div class="flex start">
          <p v-if="counts">{{ getMessage }}</p>
          <p v-if="loading">...fetching Data</p>
        </div>
        <div class="flex end">
          <button id="deleteButton" v-if="formData" type="button" class="btn btn-warning"
                  @click="removeFromStorage">Filter löschen
          </button>
          <button id="sendButton" v-if="formData" type="submit" class="btn btn-primary">{{ btntext }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {defineComponent} from "vue/dist/vue.esm-bundler.js";
import wpiAxiosInstance from '../../core/axiosInstance'
import spinner from "../spinner/spinner.vue";

export default defineComponent({
  name: "advancedSearchForm",
  props: ['btntext'],
  components: {
    spinner
  },
  data() {
    return {
      message: 'Anzahl Objekte',
      loading: false,
      counts: 0,
      selectOptions: {},
      formData: {}
    }
  },
  computed: {
    getMessage: function () {
      return `${this.message}: ${this.counts}`;
    },
  },
  mounted: function () {
    this.loading = true;

    if (localStorage.wpi_advanced_search) {
      this.formData = JSON.parse(localStorage.wpi_advanced_search);
    }
  },
  created: function () {
    const vm = this;
    // Call API
    wpiAxiosInstance.get('selectOptions')
      .then(function (res) {
        vm.loading = false;
        if (res.data) {
          vm.selectOptions = res.data;
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  },
  watch: {
    formData: {
      handler: function (newVal) {
        const vm = this;

        // write to localStorage
        localStorage.setItem('wpi_advanced_search', JSON.stringify(this.formData));
        // Abfrage Anzahl möglicher Ergebnisse
        this.loading = true;
        wpiAxiosInstance.post('advancedSearchCount', this.formData)
          .then(function (res) {
            if (res.data) {
              vm.counts = res.data;
            }
            vm.loading = false;
          })
          .catch(function (res) {
            vm.loading = false;
            console.log(res.status, res.error)
          });
      },
      deep: true
    },
    selectOptions: function (val) {
      //console.log(val);
    }
  },
  methods: {
    onSubmit: function (event) {
      // query anfrage an Server
      const vm = this;
      this.loading = true;
      // write to localStorage
      localStorage.setItem('wpi_advanced_search', JSON.stringify(this.formData));
      // send formData to backend
      wpiAxiosInstance.post('advancedSearchSubmit', this.formData)
        .then(function (res) {
          vm.loading = false;
          if (res.data.redirectTo) {
            window.location.replace(res.data.redirectTo);
          }
        })
        .catch(function () {
          vm.loading = false;
        });
    },
    removeFromStorage() {
      //clear formData
      this.formData = {};
      localStorage.removeItem('wpi_advanced_search');
    }
  },
})
</script>

<style scoped>

</style>
