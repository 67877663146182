import {createApp} from "vue";
import advancedSearchForm from "./components/advancedSearch/advancedSearchForm.vue";
import advancedUmkreisForm from "./components/advancedUmkreissuche/advancedUmkreisForm.vue";

const classes = document.querySelectorAll('.wpi');

for (const element of classes) {
  createApp({
    components: {
      advancedSearchForm,
      advancedUmkreisForm
    }
  }).mount(element)


  /*new Vue({
    components: {
      advancedSearchForm,
      advancedUmkreisForm
    }
  }).$mount(element);*/
}
