<template>
  <div class="row">
    <div class="message-wrapper">
      <spinner v-if="loading"></spinner>
<!--      <div id="message" class="col-xs-12 text-center" :class="counts ? 'visible' : 'hidden'">{{ getMessage }}</div>-->
    </div>
    <div id="form-wrapper" class="col-xs-12">
      <form class="form" @submit.prevent="onSubmit">
        <div id="plz-group" class="form-group">
          <label for="plz">PLZ</label>
          <input id="plz" type="text" placeholder="PLZ" v-model="formData.plz"/>
        </div>
        <div id="umkreis-group" class="form-group">
          <label for="umkreis">Umkreis in km</label>
          <input id="umkreis" type="text" placeholder="Umkreis in km" v-model="formData.umkreis"/>
        </div>
        <div id="objektart-group" class="form-group">
          <label for="objektart">Objektart</label>
          <select id="objektart" v-model="formData.objekttyp">
            <option value="">---</option>
            <option v-for="(objekt, key) in selectOptions.objekttyp">
              {{ selectOptions.objekttyp[key].name }}
            </option>
          </select>
        </div>
        <div id="vermarktung-group" class="form-group">
          <label for="vermarktung">Vermarktung</label>
          <select id="vermarktung" v-model="formData.vermarktung">
            <option value="">---</option>
            <option v-for="(vermarktung, key) in selectOptions.vermarktung">
              {{ selectOptions.vermarktung[key].name }}
            </option>
          </select>
        </div>
        <div id="controls" class="form-group">
          <button id="control-btn" type="submit" class="btn btn-default btn-block" :disabled="btnActive">
            {{ btnActive ? btntext : getMessage }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue/dist/vue.esm-bundler.js";
import wpiAxiosInstance from '../../core/axiosInstance'
import spinner from '../spinner/spinner.vue'

export default defineComponent({
  name: "advancedUmkreisForm",
  props: ['btntext'],
  components: {
    spinner
  },
  data() {
    return {
      message: 'Objekte gefunden',
      loading: false,
      counts: 0,
      selectOptions: {},
      formData: {objekttyp: '', vermarktung: ''}
    }
  },
  computed: {
    getMessage: function () {
      return `${this.counts} ${this.message}`;
    },
    btnActive: function () {
      return this.formData.plz && this.formData.umkreis ? false : true;
    }
  },
  mounted: function () {
    this.loading = true;

    /*if (localStorage.wpi_advanced_search) {
      this.formData = JSON.parse(localStorage.wpi_advanced_search);
    }*/
  },
  created: function () {
    const vm = this;
    // Call API
    wpiAxiosInstance.get('umkreisSelectOptions')
        .then(function (res) {
          vm.loading = false;
          if (res.data) {
            vm.selectOptions = res.data;
          }
        })
        .catch(function (res) {
          console.log(res);
        });
  },
  methods: {
    onSubmit(event) {
      // query anfrage an Server
      const vm = this;
      this.loading = true;

      // send formData to backend
      wpiAxiosInstance.post('advancedUmkreisSubmit', this.formData)
          .then(function (res) {
            vm.loading = false;
            if (res.data.redirectTo) {
              window.location.replace(res.data.redirectTo);
            }
          })
          .catch(function () {
            vm.loading = false;
          });
    }
  },
  watch: {
    formData: {
      handler: function (newVal) {
        const vm = this;

        // write to localStorage
        //localStorage.setItem('wpi_advanced_search', JSON.stringify(this.formData));
        // Abfrage Anzahl möglicher Ergebnisse nur wenn plz und umkreis angegeben wurde
        if (vm.formData?.plz && vm.formData?.umkreis) {
          this.loading = true;
          wpiAxiosInstance.post('advancedUmkreisCount', this.formData)
              .then(function (res) {
                if (res.data) {
                  vm.counts = res.data;
                }
                vm.loading = false;
              })
              .catch(function (res) {
                vm.loading = false;
                console.log(res.status, res.error)
              });
        }
      },
      deep: true
    },
    selectOptions: {
      handler: function (newVal) {
        const vm = this;
        vm.selectOptions = newVal;
      },
      deep: true
    }
  },
})
</script>

<style lang="scss" scoped>

.row:has(#form-wrapper) {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid;
  border-color: #ddd;
  border-radius: 5px;
  margin: 0;
  padding: 10px;

  .message-wrapper {
    display: flex;
    gap: 20px;

    #message {
      color: var(--primary-color);
      border-color: #ddd;
      border: 1px solid;
      border-radius: 5px;
      margin: 15px;
      padding: 10px;

      &.visible {
        display: block;
        visibility: visible;
        opacity: 1;
        height: 41.5px;
        transition: opacity 2s ease-in-out;
      }

      &.hidden {
        height: 0;
        display: none;
        visibility: hidden;
        opacity: 0;
        transition: all 2s ease-in-out;
      }
    }
  }

  #form-wrapper {
    z-index: 10;

    form.form {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;

      @media screen and (max-width: 37.5em) {
        justify-content: flex-end;
        gap: 5px;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 49%;

        @media screen and (min-width: 37.5em) {
          flex-basis: 32%;
        }

        @media screen and (min-width: 50em) {
          flex-basis: calc((100vw / 6.3));
        }

        label {
          font-weight: normal;
          color: var(--primary-color);
        }

        input, select, button {
          width: 100%;
          height: 41.5px;
          border-radius: 5px;
          color: var(--primary-color);

          &:focus-visible, &:focus {
            border: 1px solid;
            border-color: var(--primary-color);
          }
        }

        button {
          background-color: transparent;

          &:not(:disabled) {
            border-color: var(--primary-color);
          }

          &:disabled {
            color: #ddd;
          }
        }
      }
    }

  }

}
</style>
